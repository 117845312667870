import { ISignUp } from '@store/profile/types';
import { BrowserStorage } from '@utils/BrowserStorage';

export class SignUpService {
  private readonly alertKey = 'signUpState';

  private browserStorage = new BrowserStorage(this.alertKey);

  private currentSignUp: ISignUp = this.browserStorage.getObject<ISignUp>(this.alertKey);

  public get signUp(): ISignUp {
    return this.currentSignUp;
  }

  public initializeOrUpdate(data: ISignUp) {
    this.browserStorage.setObject<ISignUp>(this.alertKey, data);
    this.currentSignUp = data;
  }

  public clear() {
    this.browserStorage.clear();
  }
}
