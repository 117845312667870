import { GetMyStatistics } from '@store/profile/features/get-statistics/get-my-statistics';
import { SetSignUp } from '@store/profile/features/set-sign-up/set-sign-up';

import { ChangeTheme } from './features/change-theme/change-theme';
import { CheckNickname } from './features/check-nickname/check-nickname';
import { CancelCheckSignVisit } from './features/check-sign-visit/cancel-check-sign-visit';
import { CheckSignVisit } from './features/check-sign-visit/check-sign-visit';
import { GetAddressByLatLng } from './features/get-address-by-lat-lng.ts/get-address-by-lat-lng';
import { GetCurrentLocation } from './features/get-current-location/get-current-location';
import { GetCurrentLocationFailure } from './features/get-current-location/get-current-location-failure';
import { GetCurrentLocationSuccess } from './features/get-current-location/get-current-location-success';
import { GetFaq } from './features/get-faq/get-faq';
import { GetTimeZone } from './features/get-time-zone/get-time-zone';
import { GetTimeZoneFailure } from './features/get-time-zone/get-time-zone_failure';
import { GetTimeZoneSuccess } from './features/get-time-zone/get-time-zone_success';
import { PlatformAuth } from './features/platform-auth/platform-auth';
import { SendFeedback } from './features/send-feedback/send-feedback';
import { UpdateProfile } from './features/update-profile/update-profile';

export const updateProfileAction = UpdateProfile.action;
export const changeThemeAction = ChangeTheme.action;
export const getReauthLink = PlatformAuth.action;
export const getFaqsAction = GetFaq.action;
export const checkSignVisitAction = CheckSignVisit.action;
export const cancelSignVisitAction = CancelCheckSignVisit.action;
export const getAddressByLatLngAction = GetAddressByLatLng.action;
export const checkNicknameAction = CheckNickname.action;
export const sendFeedbackAction = SendFeedback.action;

export const getTimeZoneAction = GetTimeZone.action;
export const getTimeZoneSuccessAction = GetTimeZoneSuccess.action;
export const getTimeZoneFailureAction = GetTimeZoneFailure.action;

export const getCurrentLocationAction = GetCurrentLocation.action;
export const getCurrentLocationSuccessAction = GetCurrentLocationSuccess.action;
export const getCurrentLocationFailureAction = GetCurrentLocationFailure.action;

export const getMyStatisticsAction = GetMyStatistics.action;
export const setSignUpAction = SetSignUp.action;
