import { createAction } from '@reduxjs/toolkit';
import { ISignUp } from '@store/profile/types';
import { SagaIterator } from 'redux-saga';
import { apply } from 'redux-saga/effects';

import { SignUpService } from '../../../../services/sign-up-service';

export class SetSignUp {
  static get action() {
    return createAction<ISignUp>('SET_SIGN_UP');
  }

  static get saga() {
    return function* ({ payload }: ReturnType<typeof SetSignUp.action>): SagaIterator {
      const signUpService = new SignUpService();
      yield apply(signUpService, signUpService.initializeOrUpdate, [payload]);
    };
  }
}
