import { AxiosError } from 'axios';
import {
  CreateMeetingResult,
  ILanguage,
  IMeeting,
  ITopic,
  UpdateAttendeesResult,
  LanguageLevel,
  MeetingType,
  ICustomMeetingInfo,
  IParticipant,
  IOrganization,
  IImage,
} from 'lingopractices-models';
import { DatesFilterEnum, TDateRange } from 'screens/AllMeetings/types';
import { ICoords } from 'screens/types';

export enum TopicEnum {
  OUR = 1,
  CUSTOM = 2,
}

export enum MeetingPlace {
  Online = 1,
  Offline = 2,
}

export type TCreateMeetingData = {
  language?: ILanguage;
  level?: LanguageLevel;
  topic?: ITopic;
  customTopic?: string;
  customTopicDescription?: string;
  note?: string;
  date?: string;
  time?: string;
  location?: string;
  shortLocation?: string;
  meetingType: MeetingType;
  participantsCount: number;
  topicType: TopicEnum;
  latLng?: ICoords;
  customMeetingInfo?: ICustomMeetingInfo;
  duration: number;
  preferVideo: boolean;
};

export type IMeetingFilter = {
  languageId: string | null;
  level: LanguageLevel | null;
  date: {
    dateType: DatesFilterEnum | null;
    customDate?: TDateRange | null;
  } | null;
  place: MeetingPlace | null;
};

export interface ISelectedParticipantInfo extends IParticipant {
  meetingId: number;
  organization?: IOrganization;
  image?: IImage;
}

export interface IMeetingsState {
  meetings: {
    meetingList: IMeeting[];
    meetingListPast: IMeeting[];
    hasMore: boolean;
    hasMorePast: boolean;
  };
  myMeetings: {
    meetingList: IMeeting[];
    meetingListPast: IMeeting[];
    hasMore: boolean;
    hasMorePast: boolean;
    selectedMeeting: IMeeting | null;
  };
  filters: {
    myMeetingsFilter: IMeetingFilter;
    allMeetingsFilter: IMeetingFilter;
    isMyMeetingsOpened: boolean;
  };
  createMeetingData: TCreateMeetingData;

  requests: {
    getMeetingsPending: boolean;
    getMyMeetingsPending: boolean;
    createMeetingPending: boolean;
    joinMeetingPending: boolean;
    leaveMeetingPending: boolean;
    editMeetingNotificationPending: boolean;
    editMeetingPending: boolean;
  };
}

export interface IGetMeetingSuccessPayload {
  data: IMeeting[];
  hasMore: boolean;
  initializedByScroll: boolean;
}

export type CreateMeetingError = {
  e: CreateMeetingResult & AxiosError;
  url?: string;
};

export const createResultLabelsMap: Record<CreateMeetingResult, string> = {
  [CreateMeetingResult.CouldNotCreateMeetLink]: 'CREATE_RESULT_CouldNotCreateGoogleMeetLink',
  [CreateMeetingResult.HasMeetingSameTime]: 'CREATE_RESULT_HasMeetingSameTime',
  [CreateMeetingResult.Success]: 'CREATE_RESULT_Success',
  [CreateMeetingResult.TokenHasBeenExpiredOrRevoked]: 'CREATE_RESULT_TokenHasBeenExpiredOrRevoked',
  [CreateMeetingResult.NotEnoughCoins]: 'CREATE_RESULT_NotEnoughCoins',
  [CreateMeetingResult.AvailableOnlyForPremiumUser]: 'CREATE_RESULT_AvailableOnlyForPremiumUser',
};

export const joinResultLabelsMap: Record<UpdateAttendeesResult, string> = {
  [UpdateAttendeesResult.AllSeatsAreTaken]: 'JOIN_RESULT_AllSeatsAreTaken',
  [UpdateAttendeesResult.AlreadyInProgressOrEnded]: 'JOIN_RESULT_AlreadyInProgressOrEnded',
  [UpdateAttendeesResult.Canceled]: 'JOIN_RESULT_Canceled',
  [UpdateAttendeesResult.HasMeetingSameTime]: 'JOIN_RESULT_HasMeetingSameTime',
  [UpdateAttendeesResult.Success]: 'JOIN_RESULT_Success',
  [UpdateAttendeesResult.CouldNotJoinToMeeting]: 'JOIN_RESULT_CouldNotJoinToMeeting',
  [UpdateAttendeesResult.TokenHasBeenExpiredOrRevoked]: 'JOIN_RESULT_TokenHasBeenExpiredOrRevoked',
  [UpdateAttendeesResult.NotEnoughCoins]: 'JOIN_RESULT_NotEnoughCoins',
  [UpdateAttendeesResult.AvailableOnlyForPremiumUser]: 'JOIN_RESULT_AvailableOnlyForPremiumUser',
};
