import { CreateUserBot } from '@store/user-bots/features/create-user-bot/create-user-bot';
import { DeleteUserBot } from '@store/user-bots/features/delete-user-bot/delete-user-bot';
import { GetCurrentUserBot } from '@store/user-bots/features/get-current-user-bot/get-current-user-bot';
import { GetCurrentUserBotResult } from '@store/user-bots/features/get-current-user-bot/get-current-user-bot-result';
import { GetUserBots } from '@store/user-bots/features/get-user-bots/get-user-bots';
import { UpdateUserBot } from '@store/user-bots/features/update-user-bot/update-user-bot';
import { all, takeLatest } from 'redux-saga/effects';

export function* userBotsSagas() {
  yield all([
    takeLatest(GetUserBots.action, GetUserBots.saga),
    takeLatest(GetCurrentUserBot.action, GetCurrentUserBot.saga),
    takeLatest(GetCurrentUserBotResult.action, GetCurrentUserBotResult.saga),
    takeLatest(CreateUserBot.action, CreateUserBot.saga),
    takeLatest(DeleteUserBot.action, DeleteUserBot.saga),
    takeLatest(UpdateUserBot.action, UpdateUserBot.saga),
  ]);
}
